<template>
  <div>
    <HeadSection title="Settings | Deeplinks" permissionOfSection="deeplinks">
      <template #actions>
        <a-col :span="14" class="d-flex justify-content-end" style="gap: 10px;">
          <a-button
            v-if="editPermission"
            ref="import-button"
            type="primary"
            size="large"
            class="success-color"
            :disabled="loading"
            style="font-size: 16px; font-weight: 300; border-radius: 2px;"
            @click="toggleImportModal(true)"
          >
            Import
          </a-button>
          <a-button
            v-if="createPermission"
            type="primary"
            size="large"
            :disabled="loading"
            style="font-size: 16px; font-weight: 300; border-radius: 2px;"
            @click="$router.push('/settings/deeplinks/create')"
          >
            <a-icon type="plus" /> Add
          </a-button>
        </a-col>
      </template>
    </HeadSection>
    <MainContainer
      permissionOfSection="deeplinks"
      :loading="userLoading"
    >
      <div class="d-flex flex-column" style="gap: 20px;">
        <DeeplinksFilters
          ref="deeplinks-filter"
          :loading="loading"
          :disabled="!hasDeeplinksListData"
          :appsOptions="appsOptions"
          :appVersionsOptions="appVersionsOptions"
          @change="fetchDeepliks"
        />
        <a-table
          class="deeplinks-table"
          :dataSource="deeplinksListData"
          :pagination="false"
          :row-key="(record) => record.id"
          :customRow="customRow"
          :columns="deeplinksColumns"
          :loading="loading"
        >
          <template slot="name" slot-scope="name, record">
            <div class="d-flex align-items-center" style="gap: 10px">
              <a-tooltip
                placement="top"
                arrow-point-at-center
                :overlayStyle="{ 'white-space': 'normal' }"
                :get-popup-container="getPopupContainer"
              >
                <template slot="title">
                  <span class="font-weight-light">Mark as favourite</span>
                </template>
                <a-icon
                  class="cursor-pointer"
                  type="star"
                  :theme="record?.is_frequently_used ? 'filled' : 'outlined'"
                  :style="{ color: frequentlyUsedLoading[`${record.id}`] ? '#c8c4db' : '#1890ff' }"
                  @click.stop="changeDeeplinkFrequentlyUsed(record)"
                />
              </a-tooltip>
              <span
                class="text-primary cursor-pointer"
                @click.stop="$router.push(`/settings/deeplinks/${record.id}/view`)"
              >
                {{ record.title }}
              </span>
            </div>
          </template>
          <template slot="description" slot-scope="description">
            <span v-if="description" class="cursor-pointer font-size-12">{{ description }}</span>
            <span v-else>—</span>
          </template>
          <template slot="application" slot-scope="application">
            <a-tag v-if="application?.name" class="cursor-pointer">{{ application.name }}</a-tag>
            <span v-else>—</span>
          </template>
          <template slot="min_app_version" slot-scope="min_app_version">
            <span>{{ min_app_version ? min_app_version : '—' }}</span>
          </template>
          <template slot="examples" slot-scope="examples">
            <span v-if="!examples.length">'—'</span>
            <div v-else class="d-flex align-items-center" style="gap: 5px;">
              <a-icon type="copy" class="text-primary font-size-18" @click.stop="clipboardHandle(examples[0])" />
              <span class="deeplinks-table__example">{{ examples[0] }}</span>
              <a-tag v-if="examples.length > 1">+{{ examples.length - 1 }}</a-tag>
            </div>
          </template>
          <template slot="action" slot-scope="action, record">
            <div class="d-flex align-items-center justify-content-end" style="gap: 2px;">
              <div
                class="custom-button-icon cursor-pointer"
                style="width: 40px; height: 40px;"
                @click.stop="$router.push(`/settings/deeplinks/${record.id}/view`)"
              >
                <a-icon
                  type="eye"
                  class="font-size-18 text-orange"
                />
              </div>
              <div
                v-if="editPermission"
                class="custom-button-icon cursor-pointer"
                style="width: 40px; height: 40px;"
                @click.stop="$router.push(`/settings/deeplinks/${record.id}/edit`)"
              >
                <a-icon
                  type="edit"
                  class="font-size-18 text-primary"
                />
              </div>
            </div>
          </template>
          <template slot="footer">
            <div class="d-flex justify-content-center">
              <a-pagination
                v-model="deeplinksListMeta.page"
                :total="deeplinksListMeta.total"
                show-size-changer
                :pageSize="deeplinksListMeta.limit"
                :pageSizeOptions="deeplinksListMeta.pageSizeOptions"
                @change="changePagination"
                @showSizeChange="changeSize"
              />
            </div>
          </template>
        </a-table>
      </div>
    </MainContainer>
    <ImportModal
      :show="showImportModal"
      :actionButton="{
        text: 'Import',
        type: 'primary',
      }"
      :loading="loading"
      @close="toggleImportModal"
      @action="uploadDeeplinks"
    />
  </div>
</template>

<script>
import HeadSection from '@/components/main/headSection'
import MainContainer from '@/components/main/mainContainer'
import DeeplinksFilters from '@/views/settings/components/deeplinks/components/DeeplinksFilters.vue'
import apiClient from '@/services/axios'
import ImportModal from '@/views/apps/fortune-wheel/components/ImportModal.vue'
import { getErrorMessage } from '@/views/apps/fortune-wheel/composables/useGetErrorMessage.js'

export default {
  props: {
    createPermission: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeadSection,
    MainContainer,
    DeeplinksFilters,
    ImportModal,
  },
  data: () => ({
    deeplinksListData: [],
    deeplinksListMeta: {
      page: 2,
      limit: 10,
      total: 0,
      pageSizeOptions: ['10', '20', '40'],
    },
    appsOptions: [],
    appVersionsOptions: [],
    deeplinksColumns: [
      {
        title: 'Name',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' },
        ellipsis: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        scopedSlots: { customRender: 'description' },
        ellipsis: true,
      },
      {
        title: 'App',
        dataIndex: 'application',
        scopedSlots: { customRender: 'application' },
      },
      {
        title: 'Available from (version)',
        dataIndex: 'min_app_version',
        scopedSlots: { customRender: 'min_app_version' },
      },
      {
        title: 'Example',
        dataIndex: 'examples',
        scopedSlots: { customRender: 'examples' },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        align: 'right',
        width: '10%',
        scopedSlots: { customRender: 'action' },
      },
    ],
    isFiltered: false,
    loading: false,
    frequentlyUsedLoading: {},
    showImportModal: false,
  }),
  computed: {
    userLoading() {
      return this.$store.state?.user?.loading || false
    },
    hasDeeplinksListData() {
      return this.deeplinksListData?.length || this.isFiltered
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement
    },
    customRow(deeplink) {
      return {
        on: {
          click: (e) => {
            this.$router.push(`/settings/deeplinks/${deeplink.id}/view`)
          },
        },
      }
    },
    clipboardHandle(text) {
      this.$clipboard(text)
      this.$notification.success({
        message: 'Example successfully copied to clipboard',
      })
    },
    toggleImportModal(status = false) {
      this.showImportModal = status
      if (status && this.$refs['import-button']?.$el) {
        this.$refs['import-button'].$el.blur()
      }
    },
    async uploadDeeplinks(data) {
      try {
        this.loading = true
        const url = 'admin/deeplinks/import'

        const files = data.map(item => item?.originFileObj)
        const formData = new FormData()
        files.forEach(file => {
          formData.append('files[]', file)
        })

        const response = await apiClient.post(url, formData)
        if (response?.data?.success) {
          this.toggleImportModal()
          this.$notification.success({
            message: response?.data?.message ? response.data.message : 'Deeplinks imported successfully',
          })
          const filters = {
            ...this.$refs['deeplinks-filter'].getFilters(),
            page: this.deeplinksListMeta.page,
            limit: this.deeplinksListMeta.limit,
          }
          this.fetchDeepliks(filters)
        } else {
          getErrorMessage(response?.data)
        }
      } catch (e) {
        this.$notification.error({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    async changeDeeplinkFrequentlyUsed(deeplink) {
      const url = `admin/deeplinks/${deeplink.id}/toggle-frequently-used`
      try {
        this.$set(this.frequentlyUsedLoading, deeplink.id, true)
        const response = await apiClient.patch(url)
        if (response?.data?.success) {
          this.$notification.success({
            message: response?.data?.message ? response.data.message : 'Successfully',
          })
          const filters = {
            ...this.$refs['deeplinks-filter'].getFilters(),
            page: this.deeplinksListMeta.page,
            limit: this.deeplinksListMeta.limit,
          }
          this.fetchDeepliks(filters)
        } else {
          getErrorMessage(response?.data)
        }
      } catch (e) {
        this.$notification.error({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.$set(this.frequentlyUsedLoading, deeplink.id, false)
      }
    },
    changePagination(page) {
      const filters = {
        ...this.$refs['deeplinks-filter'].getFilters(),
        page: page,
      }
      if (this.$refs['deeplinks-filter']) {
        this.$refs['deeplinks-filter'].page = page
      }
      this.fetchDeepliks(filters)
    },
    changeSize(current, size) {
      const filters = {
        ...this.$refs['deeplinks-filter'].getFilters(),
        page: current,
        limit: size,
      }
      if (this.$refs['deeplinks-filter']) {
        this.$refs['deeplinks-filter'].page = current
        this.$refs['deeplinks-filter'].limit = size
      }
      this.fetchDeepliks(filters)
    },
    checkFilters(filters) {
      if (filters === null) {
        return false
      }
      return !!filters?.application_id || !!filters?.min_app_version || !!filters?.search
    },
    async fetchDeepliks(filters = null) {
      this.loading = true
      if (this.checkFilters(filters)) {
        this.isFiltered = true
      } else {
        this.isFiltered = false
      }
      const url = 'admin/deeplinks'
      try {
        const response = await apiClient.get(url, { params: filters })
        if (response?.data?.success) {
          this.deeplinksListData = response?.data?.data || []
          this.deeplinksListMeta = this.getTableMeta(response?.data?.meta)
          if (response?.data?.filters) {
            this.appsOptions = response?.data?.filters?.applications.map(application => {
              return { name: application.name, value: application.id, platform: application.platform }
            })
            this.appVersionsOptions = response?.data?.filters?.application_versions.map(version => {
              return { name: version, value: version }
            })
          }
          await this.$nextTick(() => {})
          setTimeout(() => {
            if (this.$refs['deeplinks-filter']) {
              this.$refs['deeplinks-filter'].setQueryParams()
            }
          }, 300)
        } else {
          getErrorMessage(response?.data)
        }
      } catch (e) {
        this.$notification.error({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    getTableMeta(meta) {
      return {
        page: meta?.current_page || 1,
        limit: Number(meta?.per_page) || 10,
        total: meta?.total || 0,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '40'],
      }
    },
    async getQueryFilters() {
      const filters = {}
      for (const [key, value] of Object.entries(this.$route.query)) {
        if (value) {
          switch (key) {
            case 'application_id':
              filters['application_id'] = value
              break
            case 'min_app_version':
              filters['min_app_version'] = value
              break
            case 'is_frequently_used':
              if (value === 'true') {
                filters['is_frequently_used'] = true
              } else {
                filters['is_frequently_used'] = false
              }
              break
            case 'search':
              filters['search'] = value
              break
            case 'page':
              filters['page'] = value
              break
            case 'limit':
              filters['limit'] = Number(value)
              break
          }
        }
      }

      await this.fetchDeepliks(filters)

      if (this.$refs['deeplinks-filter']) {
        this.$refs['deeplinks-filter'].setFilters(filters)
      } else {
        setTimeout(() => {
          this.$refs['deeplinks-filter'].setFilters(filters)
        }, 100)
      }
    },
  },
  async created() {
    if (Object.keys(this.$route.query).length === 0) {
      this.fetchDeepliks()
    } else {
      this.getQueryFilters()
    }
    window.addEventListener('popstate', this.getQueryFilters)
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.getQueryFilters)
  },
}
</script>

<style lang="scss">
.deeplinks-table {
  .ant-table-row {
    cursor: pointer;
  }
  .ant-table-footer {
    background: transparent;
  }
  &__example {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
